<template>
  <b-tabs class="home-tabs">
    <b-tab :active="alertActive">
      <template #title>
        <div class="tab-devices">
          <div class="tab-left">
            <span class="tab-title">{{$t("Devices With Active Alerts")}}</span>
          </div>
          <div class="tab-right">
            <span class="alert-text">
              <feather-icon icon="BellIcon" class="alert-icon" />
              <span class="alert-number">{{
                devicesWithAlert
              }}</span>
            </span>
          </div>
        </div>
      </template>
      <home-info-device-alerts />
    </b-tab>

    <b-tab>
      <template #title>
        <div class="tab-devices">
          <div class="tab-left">
            <span class="tab-title">{{$t("Offline Devices")}}</span>
            <span class="info-text">
              <feather-icon icon="AlertCircleIcon" class="info-icon" />
              {{$t("More than 15 minutes without data")}}
            </span>
          </div>
          <div class="tab-right">
            <span class="alert-text">
              <feather-icon icon="CloudOffIcon" class="cloud-icon" />
              <span class="alert-number">{{
                offlineDevices
              }}</span>
            </span>
          </div>
        </div>
      </template>
      <home-info-offline-devices />
    </b-tab>

    <b-tab :active="totalActive">
      <template #title >
        <div class="tab-devices">
          <div class="tab-left">
            <span class="tab-title">{{$t("Total Devices")}}</span>
          </div>
          <div class="tab-right">
            <span class="alert-text">
              <feather-icon icon="ThermometerIcon" class="thermo-icon" />
              <span class="alert-number">{{
                generalStatusHomeInfo.total
              }}</span>
            </span>
          </div>
        </div>
      </template>
      <home-info-device-total />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import HomeInfoDeviceAlerts from "./HomeInfoDeviceAlerts.vue";
import HomeInfoDeviceTotal from "./HomeInfoDeviceTotal.vue";
import HomeInfoOfflineDevices from "./HomeInfoOfflineDevices.vue"

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    HomeInfoDeviceAlerts,
    HomeInfoDeviceTotal,
    HomeInfoOfflineDevices,
  },
  data() {
  return {
    initial_date: "",
    end_date: "",
    alertActive:true,
    totalActive:false
    };
  },
  methods:
  {
  },
  computed: {
    offlineDevices() {
      return this.$store.state.devices.offlineDevices.length;
    },
    generalStatusHomeInfo() {
      let general =  this.$store.state.devices.generalStatusHomeInfo;
       if(general.active_with_alerts == 0)
        {
          this.alertActive=false
          this.totalActive=true
        }
      return general
    },
    devicesWithAlert(){
      return this.$store.state.devices.devicesWithAlert.length;
    }
  },
  created()
  {
    if(this.devicesWithAlert == 0)
    {
      this.alertActive=false
      this.totalActive=true
    }
  }

};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";

@media (max-width: 500px) {
  .tab-devices{
    max-height: 100px;
    padding: 1rem !important;
  }

  .tab-left{
    justify-content: center !important;
  }
  
  .tab-right{
    width: 50%;
  }

  .tab-title{
    font-size: 15px !important;
  }
  .alert-icon{
    width: 75px !important;
    height: 75px !important;
  }
  .cloud-icon{
    width: 75px !important;
    height: 75px !important;
  }
  .thermo-icon{
    width: 75px !important;
    height: 75px !important;
  }

  .alert-number{
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    font-size: 35px !important;
  }

  .nav-tabs{
    border: none !important;
  }

  .home-tabs{
    .nav-item{
      border-bottom: solid 1px #2f79bb;
    }
  }
  
}
</style>
