<template>
  <b-card-code>
    <b-row>
      <b-col class="col-12 col-sm-auto col-md-6">
         
            <v-select
              class="device-selects"
              :placeholder="$t('All Devices')"
              v-model="selectedDeviceType"
              :options="deviceTypeList"
              :searchable="false"
              v-on:input="filterDeviceList"
            />
         
          </b-col>
          <b-col class="col-12 col-sm-auto col-md-6">
            <b-row class="search-row">
              <b-col cols="8">
                <b-input-group class="">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    :placeholder="$t('Search')"
                    v-model="selectedDevicesSearch"
                  />
                </b-input-group>
              </b-col>
              <b-col cols="3">
                <b-button
                  class="search-btn"
                  variant="none"
                  @click="filterDeviceList"
                >
                  <span>{{ $t("GO") }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
      <b-col
        class="d-flex justify-content-end align-items-baseline"
        cols="12"
        md="8"
      >
        <b-form-group>
          <!-- <div class="d-flex align-items-center">
            <label class="mr-1">Show</label>
            <v-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-"
            />
          </div> -->
        </b-form-group>
      </b-col>
    </b-row>
    <vue-good-table
      :columns="columns"
      :rows="deviceRows"
      :rtl="direction"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
        perPageDropdown: pageOptions,
        nextLabel: $t('Next'),
        prevLabel: $t('Prev'),
        rowsPerPageLabel: $t('Rows per page'),
        ofLabel: $t('of'),
      }"
      @on-row-click="openDeviceDetails"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'typology'" class="text-nowrap">
          <span class="text-nowrap">{{ $t(props.row.typology) }}</span>
        </span>

        <span v-else-if="props.column.field === 'example2'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.example2 }}</span>
        </span>

        <span v-else-if="props.column.field === 'example3'">
          <span class="text-nowrap">{{ props.row.example3 }}</span>
        </span>

        <span v-else-if="props.column.field === 'example5'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.example5 }}</span>
        </span>

        <span v-else-if="props.column.field === 'example6'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.example6 }}</span>
        </span>

        <span v-else-if="props.column.field === 'status'" class="text-nowrap">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>
      </template>

      <!-- pagination -->
      <!-- <template slot="pagination-bottom">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to 7 </span>
            <span class="text-nowrap"> of 100 entries </span>
          </div>
          <div class="d-flex flex-wrap">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="pagination-btn mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template> -->
    </vue-good-table>

    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BButton,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import store from "@/store/index";
import { codeBasic } from "./code";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BRow,
    BCol,
    BButton,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      selectedDeviceType: null,
      selectedDevicesSearch: null,
      deviceTypeList: [
        { id: "Blast Chiller", label: this.$t("Blast Chiller") },
        { id: "Cabinet", label: this.$t("Cabinet") },
        { id: "Counter", label: this.$t("Counter") },
        { id: "Cold Room", label: this.$t("Cold Room") },
        { id: "Acura", label: this.$t("Acura") },
        { id: "Exige", label: this.$t("Exige") },
      ],

      perPage: 1,
      pageLength: 5,
      pageOptions: [5, 10, 20],
      dir: false,
      totalRows: 3,
      currentPage: 1,
      codeBasic,
      selected: { title: "Select..." },
      option: [
        { title: "Option One" },
        { title: "Option Two" },
        { title: "Option Three" },
        { title: "Option Four" },
      ],
      columns: [
        {
          label: this.$t("TYPE"),
          field: "typology",
          thClass: 'column-font-size'
        },
        {
          label: this.$t("SERIAL No."),
          field: "serial_number",
          width: 'auto',
          thClass: 'column-font-size'
        },
        {
          label: this.$t("MODEL"),
          field: "model_code",
          thClass: 'column-font-size'
        },
        {
          label: this.$t("DESCRIPTION"),
          field: "name",
          thClass: 'column-font-size'
        },
        {
          label: this.$t("SITE"),
          field: "location",
          thClass: 'column-font-size'
        },
        {
          label: this.$t("ACTIVATION"),
          field: "activation_date",
          thClass: 'column-font-size'
        },
        {
          label: this.$t("LAST UPDATE"),
          field: "last_update",
          width: 'auto',
          thClass: 'column-font-size',
          formatFn: (value) => {
            const dateTZ = new Date(`${value}Z`);
            const dayOfMonth =
              dateTZ.getDate() > 9 ? dateTZ.getDate() : `0${dateTZ.getDate()}`;
            const monthOfYear =
              dateTZ.getMonth() + 1 > 9
                ? dateTZ.getMonth() + 1
                : `0${dateTZ.getMonth() + 1}`;
            const year = dateTZ.getFullYear();
            const hourOfDay =
              dateTZ.getHours() > 9
                ? dateTZ.getHours()
                : `0${dateTZ.getHours()}`;
            const minuteOfHour =
              dateTZ.getMinutes() > 9
                ? dateTZ.getMinutes()
                : `0${dateTZ.getMinutes()}`;
            return `${year}-${monthOfYear}-${dayOfMonth} ${hourOfDay}:${minuteOfHour}`;
          },
        },
        {
          label: this.$t("STATUS"),
          field: "status",
          thClass: 'column-font-size'
        },
      ],
      rows: [],
      searchTerm: "",
      exemple7: [
        {
          1: "Activo",
          2: "Offline",
          3: "Alerta",
        },
        {
          1: "light-activo",
          2: "light-offline",
          3: "light-alerta",
        },
      ],
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        "OK!": "light-activo",
        "Ups!": "light-offline",
        "NOK!": "light-alerta",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    deviceRows() {
      return this.$store.state.devices.devicesList;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.deviceRows.length;
  },
  methods: {
    openDeviceDetails(props) {
      this.$router.push({
        name: "device",
        // preserve current path and remove the first char to avoid the target URL starting with `//`
        params: { id: props.row.id },
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    filterDeviceList() {
      this.$store.dispatch("devices/getRegisteredDevices", {
        context: this,
        typology: this.selectedDeviceType,
        search: this.selectedDevicesSearch,
      });
    },
  },
  created() {
    this.$store.dispatch("devices/getRegisteredDevices", { context: this });
  },
};
</script>
<style scoped>
@media (max-width: 500px) {
  .search-row{
    margin-top:5px;
  }

  .search-btn{
    padding: 8px 18px;
  }
}
</style>